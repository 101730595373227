import React, { useState, useEffect, useCallback } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    LinearProgress,
    Typography,
    Box,
} from '@mui/material';
import { motion } from 'framer-motion';
import { enqueueSnackbar } from "notistack";
import { useApi } from "../../context/ApiProvider";
import { LastSnapshot } from "../../types/LastSnapshot";
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';

const MotionTableRow = motion(TableRow);

export const ProfitColumn = (props: { lastSnapshot: LastSnapshot }) => {
    const lastSnapshot = props.lastSnapshot;
    const profit = Number((lastSnapshot.last_snapshot_amount - lastSnapshot.total_invested).toFixed(2));
    const profitPercentage = Number((((lastSnapshot.last_snapshot_amount - lastSnapshot.total_invested) / lastSnapshot.total_invested) * 100).toFixed(2));
    const isProfit = profit > 0;

    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            color: isProfit ? 'success.main' : 'error.main'
        }}>
            {isProfit ? <TrendingUpIcon /> : <TrendingDownIcon />}
            <Typography>
                {profit.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                {' '}
                ({profitPercentage}%)
            </Typography>
        </Box>
    );
};

const LastSnapshotTable: React.FC = () => {
    const api = useApi();
    const [isLoading, setIsLoading] = useState(true);
    const [snapshotsData, setSnapshotsData] = useState<LastSnapshot[]>([]);
    const firstRender = React.useRef(true);

    const fetchData = useCallback(() => {
        setIsLoading(true);
        api?.get(`/snapshot/last`)
            .then(response => {
                if (!response.ok) {
                    enqueueSnackbar('Failed to fetch snapshots data!', { variant: 'error' });
                }
                return setSnapshotsData(response.body ?? []);
            })
            .catch((error) => {
                console.error('Error:', error);
                enqueueSnackbar('Failed to fetch snapshots data!', { variant: 'error' });
                setSnapshotsData([]);
            })
            .finally(() => setIsLoading(false));
    }, [api]);

    useEffect(() => {
        if (firstRender.current) {
            fetchData();
            firstRender.current = false;
        }
    }, [fetchData]);

    return (
        <div>
            <Typography variant="h2" component="h1" gutterBottom sx={{
                fontWeight: 'bold',
                color: 'primary.main',
                mb: 4
            }}>
                Investment Dashboard
            </Typography>
            {isLoading ? (
                <LinearProgress sx={{ mb: 2 }} />
            ) : (
                <TableContainer
                    component={Paper}
                    sx={{
                        overflow: 'hidden',
                        '& .MuiTableCell-root': {
                            fontSize: '1rem',
                        },
                    }}
                >
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Total Invested</TableCell>
                                <TableCell>Current Value</TableCell>
                                <TableCell>Profit/Loss</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {snapshotsData.map((row: LastSnapshot, index) => (
                                <MotionTableRow
                                    key={row.investor_id}
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 0.3, delay: index * 0.1 }}
                                    sx={{
                                        '&:hover': {
                                            backgroundColor: 'action.hover',
                                        },
                                    }}
                                >
                                    <TableCell>{row.investor_id}</TableCell>
                                    <TableCell>
                                        <Typography fontWeight="medium">
                                            {row.name}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        {row.total_invested.toLocaleString('en-US', {
                                            style: 'currency',
                                            currency: 'USD'
                                        })}
                                    </TableCell>
                                    <TableCell>
                                        {row.last_snapshot_amount.toLocaleString('en-US', {
                                            style: 'currency',
                                            currency: 'USD'
                                        })}
                                    </TableCell>
                                    <TableCell>
                                        <ProfitColumn lastSnapshot={row} />
                                    </TableCell>
                                </MotionTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </div>
    );
};

export default LastSnapshotTable;