import React, { useState, useEffect, useCallback } from 'react';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    Area,
    AreaChart
} from 'recharts';
import { Box, Paper, Typography, LinearProgress, ButtonGroup, Button, useTheme } from '@mui/material';
import { useApi } from "../../context/ApiProvider";
import { Snapshot } from "../../types/Snapshot";
import { enqueueSnackbar } from "notistack";

type TimeRange = '1w' | '2w' | '1m' | '3m' | 'all';

const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
        return (
            <Paper sx={{
                p: 2,
                boxShadow: 2,
                border: '1px solid rgba(0,0,0,0.1)',
                backgroundColor: 'rgba(255,255,255,0.95)'
            }}>
                <Typography variant="body2" color="text.secondary">
                    {label}
                </Typography>
                <Typography variant="body1" fontWeight="bold" color="primary">
                    ${payload[0].value.toFixed(2)}
                </Typography>
            </Paper>
        );
    }
    return null;
};

const SnapshotGraph: React.FC = () => {
    const theme = useTheme();
    const api = useApi();
    const [isLoading, setIsLoading] = useState(true);
    const [snapshotData, setSnapshotData] = useState<Snapshot[]>([]);
    const [timeRange, setTimeRange] = useState<TimeRange>('1m');

    const fetchData = useCallback(() => {
        setIsLoading(true);
        api?.get('/snapshot/find')
            .then(response => {
                if (!response.ok) {
                    enqueueSnackbar('Failed to fetch snapshot data!', { variant: 'error' });
                    return;
                }
                setSnapshotData(response.body ?? []);
            })
            .catch((error) => {
                console.error('Error:', error);
                enqueueSnackbar('Failed to fetch snapshot data!', { variant: 'error' });
                setSnapshotData([]);
            })
            .finally(() => setIsLoading(false));
    }, [api]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const filterDataByTimeRange = (data: Snapshot[], range: TimeRange) => {
        if (range === 'all') return data;

        const now = new Date();
        const ranges = {
            '1w': 7,
            '2w': 14,
            '1m': 30,
            '3m': 90
        };

        const daysToSubtract = ranges[range] || 0;
        const cutoffDate = new Date(now.setDate(now.getDate() - daysToSubtract));

        return data.filter(snapshot => new Date(snapshot.created_at) >= cutoffDate);
    };

    const formatData = (data: Snapshot[]) => {
        const filteredData = filterDataByTimeRange(data, timeRange);

        const groupedData = filteredData.reduce((acc: { [key: string]: number }, snapshot) => {
            const date = new Date(snapshot.created_at).toLocaleDateString('en-US', {
                month: 'short',
                day: 'numeric'
            });
            acc[date] = (acc[date] || 0) + parseFloat(snapshot.amount);
            return acc;
        }, {});

        return Object.entries(groupedData)
            .map(([date, total]) => ({
                date,
                total: Number(total.toFixed(2))
            }))
            .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
    };

    if (isLoading) {
        return <LinearProgress sx={{ mt: 4 }} />;
    }

    const formattedData = formatData(snapshotData);

    return (
        <Paper
            elevation={0}
            sx={{
                p: 3,
                mt: 4,
                border: '1px solid',
                borderColor: 'divider',
                borderRadius: 2,
                bgcolor: '#ffffff'
            }}
        >
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 4,
                flexWrap: 'wrap',
                gap: 2
            }}>
                <Typography
                    variant="h3"
                    component="h2"
                    sx={{
                        fontWeight: 600,
                        color: 'text.primary',
                        fontSize: { xs: '1.5rem', md: '2rem' }
                    }}
                >
                    Wallet Performance
                </Typography>
                <ButtonGroup
                    variant="outlined"
                    size="small"
                    sx={{
                        '& .MuiButton-root': {
                            borderColor: 'divider',
                            color: 'text.secondary',
                            '&.Mui-selected, &:hover': {
                                bgcolor: 'primary.main',
                                color: 'white'
                            }
                        }
                    }}
                >
                    {[
                        { value: '1w', label: '1W' },
                        { value: '2w', label: '2W' },
                        { value: '1m', label: '1M' },
                        { value: '3m', label: '3M' },
                        { value: 'all', label: 'All' }
                    ].map((option) => (
                        <Button
                            key={option.value}
                            onClick={() => setTimeRange(option.value as TimeRange)}
                            variant={timeRange === option.value ? 'contained' : 'outlined'}
                        >
                            {option.label}
                        </Button>
                    ))}
                </ButtonGroup>
            </Box>
            <Box sx={{ width: '100%', height: 400 }}>
                <ResponsiveContainer>
                    <AreaChart
                        data={formattedData}
                        margin={{
                            top: 10,
                            right: 30,
                            left: 20,
                            bottom: 20,
                        }}
                    >
                        <defs>
                            <linearGradient id="totalGradient" x1="0" y1="0" x2="0" y2="1">
                                <stop
                                    offset="5%"
                                    stopColor={theme.palette.primary.main}
                                    stopOpacity={0.1}
                                />
                                <stop
                                    offset="95%"
                                    stopColor={theme.palette.primary.main}
                                    stopOpacity={0}
                                />
                            </linearGradient>
                        </defs>
                        <CartesianGrid
                            strokeDasharray="3 3"
                            vertical={false}
                            stroke={theme.palette.divider}
                        />
                        <XAxis
                            dataKey="date"
                            tick={{ fontSize: 12, fill: theme.palette.text.secondary }}
                            axisLine={{ stroke: theme.palette.divider }}
                            tickLine={false}
                        />
                        <YAxis
                            tick={{ fontSize: 12, fill: theme.palette.text.secondary }}
                            axisLine={false}
                            tickLine={false}
                            tickFormatter={(value) => `$${value}`}
                        />
                        <Tooltip content={<CustomTooltip />} />
                        <Area
                            type="monotone"
                            dataKey="total"
                            stroke={theme.palette.primary.main}
                            strokeWidth={2}
                            fill="url(#totalGradient)"
                            dot={false}
                            activeDot={{
                                r: 6,
                                strokeWidth: 2,
                                fill: theme.palette.background.paper,
                                stroke: theme.palette.primary.main
                            }}
                        />
                    </AreaChart>
                </ResponsiveContainer>
            </Box>
        </Paper>
    );
};

export default SnapshotGraph;