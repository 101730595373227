import * as React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ThemeProvider, CssBaseline, Container, Box } from '@mui/material';
import LastSnapshotTable from "./components/Investor/LastSnapshotTable";
import SnapshotGraph from "./components/Investor/SnapshotGraph";
import theme from './theme';

export default function App() {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Container maxWidth="lg">
                <Box sx={{
                    py: 4,
                    minHeight: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 3
                }}>
                    <BrowserRouter>
                        <Routes>
                            <Route index element={
                                <>
                                    <SnapshotGraph />
                                    <LastSnapshotTable />
                                </>
                            } />
                        </Routes>
                    </BrowserRouter>
                </Box>
            </Container>
        </ThemeProvider>
    );
}